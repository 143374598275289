import parsePhoneNumberFromString from "libphonenumber-js";
import * as yup from "yup";

const locationSchema = yup
  .object({
    name: yup.string().required("Location is required"),
    code: yup.string().required("Location is required"),
    type: yup.string().required("Location is required"),
    label: yup.string().required("Location is required"),
  })
  .required("Location is required");

export const HomeSearchSchema = yup.object().shape({
  location: locationSchema,
  dates: yup
    .array()
    .of(yup.mixed().required("Dates are required"))
    .min(2, "Please select a date range"),
  guests: yup
    .array()
    .of(
      yup.object().shape({
        adults: yup
          .number()
          .min(1, "At least one adult is required")
          .required(),
        children_ages: yup.array().of(yup.number().min(0)).required(),
      })
    )
    .min(1, "At least one room is required"),
});

export const promoCodeSchema = yup.object({
  promo_code: yup.string().required("Promo code is required"),
});

export const cancelBookingSchema = yup.object({
  reason: yup.string().required("Reason is required"),
  comments: yup.string().optional().nullable(),
});

export const dealsSchema = yup.object({
  deal_id: yup.string().required("ID is required"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone_number: yup
    .string()
    .required("Phone number is required")
    .test(
      "is-valid-phone",
      "Please enter a valid phone number",
      function (value) {
        const { phone_number_country_code } = this.parent;
        if (!value || !phone_number_country_code) return false;
        const phoneNumber = parsePhoneNumberFromString(
          value,
          phone_number_country_code
        );
        return phoneNumber?.isValid() || false;
      }
    ),
  phone_number_country_code: yup.string().required("Country code is required"),
  // phone_number: yup.string().nullable(),
  // phone_code: yup.string().nullable(),
});
