"use client";

import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { saveUserCurrency, saveUserCurrentCountry } from "@/lib/features/home/homeSlice";
import { currencies } from "@/data/currencies";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { getHotels, saveSearchPayload } from "@/lib/features/hotel/hotelSlice";
import { transformGuests } from "@/utils/helper";
import { useSession } from "next-auth/react";
const CurrenctyMegaMenu = ({ textClass }: { textClass: string }) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const queryParams: any = useSearchParams();
  const currentPage = usePathname();
  const sortedCurrencies = currencies?.sort(
    (a: any, b: any) => a?.code?.localeCompare(b.code)
  )
  const allCurrencies = sortedCurrencies?.filter((item) => item?.show == true);
  const userCurrency = useAppSelector((state) => state.home.userCurrency);

  const dispatch = useAppDispatch();
  const [click, setClick] = useState<boolean>(false);
  const handleCurrency = useCallback(() => {
    if(session?.user) {
      return;
    }
    setClick((prevState) => !prevState)
  },[session]);

  const handleItemClick = useCallback(async(item: any) => {
    const userExistingCurrency = userCurrency?.code;
    const userNewCurrency = item?.code;
    if(userExistingCurrency != userNewCurrency){
      dispatch(saveUserCurrency(item));
      dispatch(saveUserCurrentCountry(item?.nationality_code));
      setClick(false);
      if(currentPage == "/hotels" && queryParams.get("payload")?.location?.code != ""){
        const decodedQueryParams = JSON.parse(queryParams.get("payload"));

        const payloadString = {
          ...decodedQueryParams,
          currency: userNewCurrency,
          nationality: item?.nationality_code,
        };
        const isSearchPayloadSaved = await dispatch(
          saveSearchPayload(payloadString)
        ).unwrap();

        console.log("After will", isSearchPayloadSaved)
        const encodedPayload = decodeURIComponent(JSON.stringify(payloadString));
        const queryString = encodedPayload.toString();
        // console.log("FINAL QUERY will", queryString)
        const { location, dates, guests, currency, nationality } = payloadString
        dispatch(
          getHotels({
            url: "v1/hotels",
            payload: {
              code: location?.code,
              type: location?.type,
              checkin: dates && dates[0],
              checkout: dates && dates[1],
              rooms: JSON.stringify(transformGuests(guests)),
              rates: "concise",
              currency,
              nationality,
            },
          })
        );

        router.push(`/hotels?payload=${queryString}`);
      }
    }
  },[queryParams, currentPage, userCurrency]);

  useEffect(() => {
    if (!userCurrency) {
      const currencyData: any = currencies?.filter((item) => item.nationality_code == session?.user?.country_code);
      dispatch(saveUserCurrentCountry(session?.user?.country_code || currencies[0]?.nationality_code));
      dispatch(saveUserCurrency(currencyData || currencies[0])); 
    }
  }, [userCurrency]);
  return (
    <div className="">
      {/* Start currencty dropdown wrapper */}
      <div className={`${session?.user ? "pe-none": ""}`}>
        <button
          className={`d-flex items-center text-14 ${textClass}`}
          onClick={handleCurrency}
        >
          <span className="js-currencyMenu-mainTitle">
            {userCurrency?.name}
          </span>
          <i className="icon-chevron-sm-down text-7 ml-10" />
        </button>
      </div>
      {/* End currencty dropdown wrapper */}

      <div
        className={`currencyMenu js-currencyMenu ${click ? "" : "is-hidden"}`}
      >
        <div className="currencyMenu__bg" onClick={handleCurrency}></div>
        <div className="currencyMenu__content bg-white rounded-4">
          <div className="d-flex items-center justify-between px-30 py-20 sm:px-15 border-bottom-light">
            <div className="text-20 fw-500 lh-15">Select your currency</div>
            {/* End Title */}

            <button className="pointer" onClick={handleCurrency}>
              <i className="icon-close" />
            </button>
            {/* End colse button */}
          </div>
          {/* End flex wrapper */}
          <ul
            className="modalGrid px-30 py-30 sm:px-15 sm:py-15"
            style={{ maxHeight: "500px", overflowY: "scroll" }}
          >
            {allCurrencies?.length > 0 &&
              allCurrencies?.map((item) => (
                <li
                  className={`modalGrid__item js-item ${
                    userCurrency?.name === item?.name ? "active" : ""
                  }`}
                  key={item?.id}
                  onClick={() => handleItemClick(item)}
                >
                  <div className="py-10 px-15 sm:px-5 sm:py-5">
                    <div className="text-15 lh-15 fw-500 text-dark-1">
                      {item?.code}
                    </div>
                    <div className="text-14 lh-15 mt-5">
                      <span className="js-title">{item?.name}</span>{" "}-{" "}
                      {item?.symbol}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CurrenctyMegaMenu;
