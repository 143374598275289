// import StarRatings from "react-star-ratings";
import { Rating } from 'react-simple-star-rating'
import "./StarRating.css";
interface MapComponentProps {
  value: any;
}

export const StarRating = ({ value }: MapComponentProps) => {
  const val = parseFloat(value  || 0) > 5 ? 5 : parseFloat(value  || 0);
  return (
    <>
      <div >
        <Rating
          iconsCount={5}
          initialValue={val}
          fillColor={"#f8d448"}
          emptyColor="lightgray"
          size={12}
          tooltipDefaultText={`${val} star hotel`}
          allowHover={false}
          showTooltip={false}
          readonly={true}
          allowFraction={false}
        />
      </div>
      {/* <StarRatings
      rating={parseFloat(value.toString() || 0)}
      starRatedColor="#f8d448"
      starDimension="12px"
      starSpacing="1px"
      numberOfStars={5}
      name="rating"
    /> */}
    </>
  );
};
